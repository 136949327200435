import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form, Select, Input, Radio, DatePicker, Spin, Table } from 'antd';
import moment from "moment"
import ApiCalls from '../../../apis/ApiCalls';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 15px;
`;

const GridItem = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 16px;
  margin: 0 0 10px 0;
`;

const Subtitle = styled.p`
  font-size: 14px;
  margin: 5px 0;
`;
const StyledCol = styled(Col)`



    .ant-radio-button-wrapper {
      width: 200px;
      display: flex;
      justify-content: center;
      /* height: 50px; */
      align-items: center;
      /* margin: 12px */
      border-radius: 5px;
      height:57px;
      text-transform:capitalize;

    }
    .ant-radio-group {
      display: flex;
      width: 100%;

    }
    .ant-radio-button-wrapper-checked {
      color: '#000000';
      height:57px;
    }
    .ant-picker {
      width: 100%;
      border-radius: 5px;
      padding: 15.5px ;
      text-transform:capitalize;

    }
    .ant-btn {
      border-radius: 25px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      width: 170px;
      height: 40px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      color: #ffffff;
    }

    .ant-form-item-label > label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #6b6b6b;   
      padding-left: 15px;
      text-transform:capitalize;

    }

    .ant-select-multiple .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 5px;
      padding: 10.5px ;
      text-transform:capitalize;

    }

    .ant-form-item-label > label.ant-form-item-required::before {
      display: none;
    }

    .ant-input-lg {
      padding: 15.5px 15px;
      font-size: 16px;
      color: #2f2f2f;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      text-transform:capitalize;

    }

    
  `

const StyledInput = styled(Input)`
    border-radius: 5px;
  `



const ProfileInfo = (props) => {

    const { doctor } = props
    const dataSource = doctor.upcomingSchedule.map((data) => {
        return {
            key: data._id,
            name: data.patientName,
            startTime: moment(data.startTime).format('hh:mm A'),
            date: moment(data.startTime).format('YYYY-MM-DD'),
        };
    });

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
    ];

    const getCurrentTime = () => {
        return new Date();
    };

    const formatToDate = (timeString) => {
        const [time, period] = timeString.split(' ');
        const [hours, minutes] = time.split(':');

        let formattedHours = parseInt(hours);
        if (period === 'PM' && formattedHours < 12) formattedHours += 12;
        if (period === 'AM' && formattedHours === 12) formattedHours = 0;

        const formattedTime = new Date();
        formattedTime.setHours(formattedHours, parseInt(minutes), 0, 0);

        return formattedTime;
    };

    const currentTime = getCurrentTime();

    const validSlots = doctor.availableSlots.filter((slot) => {
        const slotTime = formatToDate(slot);
        return slotTime > currentTime;
    });

    const [initialValues, setInitialValues] = useState({})
    const [specializationList, setSpecializationList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setInitialValues({
            firstName: doctor.firstName,
            lastName: doctor.lastName,
            gender: doctor.gender,
            email: doctor.email,
            city: doctor.city,
            dob: doctor.dob
        })
    }, [])

    useEffect(() => {
        setLoading(true)
        ApiCalls.getSpecialisationList().then(res => {
            if (res.data.status === 1) {
                setLoading(false)
                setSpecializationList(res.data.data)
            }
        })
    }, [])



    return (
        <Spin spinning={loading}>


            <Form
                layout='vertical'
                initialValues={{
                    ...doctor
                }}

            >
                <Row gutter={24}>
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 10 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='firstName'
                            label='First Name*'
                        >
                            <StyledInput placeholder='First Name' size='large' readOnly />
                        </Form.Item>
                    </StyledCol>
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 10 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='lastName'
                            label='Last Name*'
                        >
                            <StyledInput placeholder='Last Name' size='large' readOnly />
                        </Form.Item>
                    </StyledCol>
                </Row>
                <Row gutter={24}>
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='gender'
                            label='Gender*'
                        >
                            {/* <Radio.Group default='male' size='large'  >
                                <Radio.Button value='male'>Male</Radio.Button>
                                <Radio.Button value='female'>Female</Radio.Button>
                            </Radio.Group> */}
                            <Input readOnly size='large' />
                        </Form.Item>
                    </StyledCol>
                    {/* <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item name='dob' label='Date of Birth'>
                            <Input size='large' readOnly/>
                        </Form.Item>
                    </StyledCol> */}
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        lg={{ span: 12 }}
                        md={{ span: 12 }}
                    >

                        <Form.Item
                            name='specialization'
                            label='Specialization*'
                        >
                            <Select
                                placeholder='Select Specialization'
                                mode='multiple'
                                size='large'
                                open={false}
                                removeIcon={false}
                            >
                                {specializationList &&
                                    specializationList.map(({ _id, specialization }) => (
                                        <Select.Option value={_id}>
                                            {specialization}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </StyledCol>

                </Row>
                <Row gutter={24}>
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 10 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='email'
                            label='Email*'
                        >
                            <StyledInput readOnly placeholder='Email' size='large' style={{ textTransform: "lowercase" }} />
                        </Form.Item>
                    </StyledCol>
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 10 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='state'
                            label='State'
                        >
                            {/* <Select
                        placeholder='Select State'
                        // mode="multiple"
                        size='large'
                    >
                        {props.cities.map(city => (
                            <Select.Option value={city._id}>{city.name}</Select.Option>
                        ))}
                    </Select> */}
                            <StyledInput size='large' readOnly defaultValue="NA" />

                        </Form.Item>
                    </StyledCol>
                </Row>
                <Row gutter={24}>
                    {/* <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='officePhone'
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "Office phone number is required",
                            //     },
                            // ]}
                            label='Office Phone*'

                        >
                            <StyledInput placeholder='Office Phone' size='large' readOnly />
                        </Form.Item>
                    </StyledCol> */}
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='mobile'
                            label='Mobile Number'
                        >
                            <StyledInput placeholder='Cell Phone' size='large' readOnly />
                        </Form.Item>
                    </StyledCol>
                </Row>
            </Form >
            <Row gutter={24}>
                {props.doctor.educationalDetails.length > 0 && (
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <p style={{ fontSize: '20px', color: '#6b6b6b', paddingLeft: '15px' }}>
                            Educational Qualification
                        </p>
                        <GridContainer>
                            {props.doctor.educationalDetails.map(item => (
                                <GridItem key={item._id}>
                                    <Title>{item.instituteName}</Title>
                                    <Subtitle><strong>Qualification:</strong> {item.qualifiction}</Subtitle>
                                    <Subtitle><strong>Address:</strong> {item.instituteAddress}</Subtitle>

                                    {item.url && (
                                        <div style={{ marginTop: '10px' }}>
                                            <img
                                                src={item.url}
                                                alt={`Document for ${item.instituteName}`}
                                                style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
                                            />
                                        </div>
                                    )}
                                </GridItem>
                            ))}
                        </GridContainer>
                    </Col>
                )}
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <p style={{ fontSize: '20px', color: '#6b6b6b', paddingLeft: '15px' }}>
                        Upcoming Appointments
                    </p>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        scroll={{ y: 400 }}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                {props.doctor.experienceDetails.length > 0 && (
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <p style={{ fontSize: '20px', color: '#6b6b6b', paddingLeft: '15px' }}>
                            Experience Details
                        </p>
                        <GridContainer>
                            {props.doctor.experienceDetails.map(item => (
                                <GridItem key={item._id}>
                                    <Title>{item.hospitalName}</Title>
                                    <Subtitle><strong>Address:</strong> {item.hospitalAddress}</Subtitle>
                                    <Subtitle><strong>Reference Person:</strong> {item.referencePerson}</Subtitle>
                                    <Subtitle><strong>Contact Number:</strong> {item.contactNumber}</Subtitle>
                                    {item.url && (
                                        <div style={{ marginTop: '10px' }}>
                                            <img
                                                src={item.url}
                                                alt={`Document for ${item.hospitalName}`}
                                                style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
                                            />
                                        </div>
                                    )}
                                </GridItem>
                            ))}
                        </GridContainer>
                    </Col>
                )}
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <p style={{ fontSize: '20px', color: '#6b6b6b', paddingLeft: '15px' }}>
                        Available Slots
                    </p>
                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {validSlots.map((data, index) => (
                            <span
                                key={index}
                                style={{
                                    background: '#E3E3E34D',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                    display: 'inline-block',
                                }}
                            >
                                {data}
                            </span>
                        ))}
                    </div>
                </Col>
            </Row>
            <Row>
                {props.doctor.medicalLicences.length > 0 && (
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <p style={{ fontSize: '20px', color: '#6b6b6b', paddingLeft: '15px' }}>
                            Licence Details
                        </p>
                        <GridContainer>
                            {props.doctor.medicalLicences.map((item, index) => (
                                <GridItem key={item._id}>
                                    <Title>Licence {index + 1}</Title>
                                    <Subtitle><strong>Country:</strong> {item.licenceCountry}</Subtitle>
                                    <Subtitle><strong>Licence Number:</strong> {item.licenceNumber}</Subtitle>
                                    <Subtitle><strong>NPI Number:</strong> {item.npiNumber}</Subtitle>
                                    <Subtitle><strong>Expiration Date:</strong> {new Date(item.licenceExpirationDate).toLocaleDateString()}</Subtitle>
                                </GridItem>
                            ))}
                        </GridContainer>
                    </Col>
                )}
            </Row>
        </Spin >
    )
}

export default ProfileInfo