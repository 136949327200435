import React from 'react';
import { Empty } from 'antd';
import CustomText from './CustomText';
const EmptyComponent = ({ message="",minHeight='50vh' }) => {
    return (
        <div style={{ minHeight: `${minHeight}`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Empty description={<CustomText color="grey">{message}</CustomText>} />
        </div>
    )
}
export default EmptyComponent