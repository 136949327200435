import React from "react";
import CustomText from "../../../../Components/CustomText";
import { Row, Col } from "antd";
import styled from "styled-components";

const TransactionCard = ({
  transactionId,
  patientPaymentAmount,
  patientPaidCurrency,
  paymentStatus,
}) => {
  const CardWrapper = styled.div`
    min-height: 250px;
    width: 450px;
    border: 1px solid #d9d9d9;
    padding: 16px;
    margin: 15px 0;
    margin-right: 10px;
    .header-wrapper {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 16px;
    }
    .content-wrapper {
      & > div {
        margin: 8px;
      }
    }
  `;

  const ImageWrapper = styled.div`
    & > img {
      height: 100px;
      width: 200px;
    }
  `;
  return (
    <CardWrapper>
      <div className="content-wrapper">
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Transaction Id:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {transactionId}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Amount:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {patientPaymentAmount}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Currency:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {patientPaidCurrency}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Payment status:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {paymentStatus}
            </CustomText>
          </Col>
        </Row>
      </div>
    </CardWrapper>
  );
};

export default TransactionCard;
