import React, { useState } from "react";
import styled from "styled-components";
import { Typography, Row, Col, Button, Upload } from "antd";
import ProfileAvatar from "../../../Components/ProfileAvatar";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect } from "react";

const { Title, Text } = Typography;

const ProfileTitle = ({ doctor, handleModelConfirm, tax }) => {
  const PrimaryButton = styled.div`
    .ant-btn-primary {
      border-radius: 25px;
      height: 40px;
      font-size: 12px;
      width: 150px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      /* svg{
        margin-right:10px;
        align-self: center;
    } */
    }
  `;

  const StyledCol = styled(Col)`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  const RemoveButton = styled(Button)`
    background-color: #dcdcdc;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    color: #a9a9a9;
    border: 0;
  `;

  const CardTitle = styled(Typography)`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #2959a8;
    text-transform: capitalize;
  `;
  const CardSubTitle = styled(Typography)`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #aeaeae;
  `;

  return (
    <Row gutter={16} align="middle" justify="space-between">
      <Col style={{ display: "flex" }}>
        <Col>
          <ProfileAvatar
            icon={<UserOutlined />}
            url={doctor.profilePic}
            size={90}
          />
        </Col>
        <StyledCol>
          <div>
            <Row>
              <CardTitle>{`${doctor.name || doctor.fullName}`}</CardTitle>
            </Row>
            <Row>
              <CardSubTitle>{doctor.email}</CardSubTitle>
            </Row>
          </div>
        </StyledCol>
      </Col>
      <Col>
        <PrimaryButton>
          {tax ? (
            <RemoveButton
              type="primary"
              style={{ fontSize: "16px" }}
              onClick={() => handleModelConfirm()}
            >
              Save
            </RemoveButton>
          ) : doctor.action === "accept" ? (
            <RemoveButton
              type="primary"
              style={{ fontSize: "16px" }}
              // icon={<DeleteOutlined />}
              onClick={() => handleModelConfirm("accept")}
            >
              {" "}
              {doctor.action === "accept" ? "Accept" : "Reject"}{" "}
            </RemoveButton>
          ) : (
            <RemoveButton
              type="primary"
              style={{ fontSize: "16px" }}
              // icon={<DeleteOutlined />}
              onClick={() => handleModelConfirm("reject")}
            >
              {" "}
              {doctor.action === "accept" ? "Accept" : "Reject"}
            </RemoveButton>
          )}
        </PrimaryButton>
      </Col>
    </Row>
  );
};
export default ProfileTitle;
