
const setAuthToken = (authToken = "") => ({
    type: "SET_AUTH_TOKEN",
    authToken
})
const removeAuthToken = () => ({
    type: "REMOVE_AUTH_TOKEN"
})

const setUserData = (userData = {}) => ({
    type: "SET_USER_DATA",
    userData
})

const removeUserData = () => ({
    type: "REMOVE_USER_DATA",
})

export default {
    setAuthToken,
    removeAuthToken,
    setUserData,
    removeUserData,
}


