import { Divider, Row, Col, Spin } from "antd";
import React, { useEffect } from "react";

import PatientCard from "./PatientData";
import TransactionCard from "./TransactionData";
import ScheduleCard from "./ScheduleData";
import DoctorDetailsCard from "./DoctorData";
import MainCard from "./MainData";

import CustomText from "../../../Components/CustomText";
import { useState } from "react";
import ApiCalls from "../../../apis/ApiCalls";
import styled from "styled-components";

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const DetailsContainer = styled.div`
  height: 700px;
  min-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function DetailsModal({ details }) {
  useEffect(() => { }, []);

  console.log(details);

  return (
    <div>
      <SectionContainer>
        <Col>
          <CustomText color="#000" size="16px" fontWeight="bold">
            Details
          </CustomText>
        </Col>
      </SectionContainer>
      <DetailsContainer>
        { } <Divider />
        {/* <Row gutter={16}>
          <Col span={12} style={{ marginBottom: "16px" }}>
            <CustomText color="grey" fontWeight="bold">
              Doctor Information
            </CustomText>
            <DoctorInfoCard doctor={selectedDoctor} />
          </Col> */}
        {/* <Col span={12}>
          <CustomText color="grey" fontWeight="bold">
            Specialization
          </CustomText>
          <ConsultationInfoCard
            consultationInfo={selectedClaim.consultationInfo}
          />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText color="grey" fontWeight="bold">
            Insurance Details
          </CustomText>
          <InsuranceInfoCard
            insuranceInfo={selectedClaim.insuranceCardDetails}
            transactionInfo={selectedClaim.transactionDetails}
          />
        </Col> */}
        {/* </Row> */}
        <Row>
          <Col>
            <CustomText color="grey" fontWeight="bold">
              Main details
            </CustomText>
          </Col>
        </Row>
        <SectionContainer className="patient-section-container">
          <MainCard {...details} />
        </SectionContainer>
        {/* <Row>
          <Col>
            <CustomText color="grey" fontWeight="bold">
              Patient Data
            </CustomText>
          </Col>
        </Row>
        <SectionContainer className="patient-section-container">
          <PatientCard {...details.patientData} />
        </SectionContainer>
        <Row>
          <Col>
            <CustomText color="grey" fontWeight="bold">
              Therapist Data
            </CustomText>
          </Col>
        </Row>
        <SectionContainer className="doctor-section-container">
          <DoctorDetailsCard {...details.doctorData} />
        </SectionContainer>
        <Row>
          <Col>
            <CustomText color="grey" fontWeight="bold">
              Transaction Data
            </CustomText>
          </Col>
        </Row>
        <SectionContainer className="transaction-section-container">
          <TransactionCard {...details.transactionData} />
        </SectionContainer>
        <Row>
          <Col>
            <CustomText color="grey" fontWeight="bold">
              Schedule Data
            </CustomText>
          </Col>
        </Row>
        <SectionContainer className="transaction-section-container">
          <ScheduleCard {...details.scheduleData} />
        </SectionContainer> */}
      </DetailsContainer>
    </div>
  );
}
