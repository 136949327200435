import qs from "qs";
import axiosInstance from "./AxiosInterceptor";

export default class ApiCalls {
  static updatePatientInsuranceStatus(id, status) {
    return axiosInstance({
      url: `/hospital/healthcard/approve`,
      method: "patch",
      data: { status: status, id: id },
    });
  }
  static getPatientInsuranceList(params) {
    return axiosInstance({
      url: "/hospital/patient/list",
      method: "get",
      params: params,
    });
  }

  static updateInsuranceClaim(model) {
    return axiosInstance({
      url: "/hospital/insurance-claim/update",
      method: "PATCH",
      data: model,
    });
  }
  static updateInstitution(model) {
    return axiosInstance({
      url: "/admin/edit",
      method: "POST",
      data: model,
    });
  }
  static getInsuranceClaimRequests(params) {
    return axiosInstance({
      url: "/hospital/insurance-claim/list",
      method: "get",
      params: params,
    });
  }

  static addPharmacy(model) {
    return axiosInstance({
      url: "/admin/pharmacy",
      method: "POST",
      data: model,
    });
  }

  static addLaboratory(model) {
    return axiosInstance({
      url: "/admin/laboratory",
      method: "POST",
      data: model,
    });
  }

  static getInstitutionList(type, text, page, limit) {
    return axiosInstance({
      url: "/admin/list",
      method: "GET",
      params: {
        type: type,
        text: text,
        page: page,
        limit: limit,
      },
    });
  }
  static updateProfilePicture(model) {
    return axiosInstance({
      url: "/user/profile-pic",
      method: "PATCH",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: model,
    });
  }

  static updateHospitalProfile(model) {
    return axiosInstance({
      handlerEnabled: false,
      url: "/admin/hospital/edit",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: model,
    });
  }

  static getSpecialisationList() {
    return axiosInstance({
      handlerEnabled: false,
      url: "/public/provider-credentials",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getAvailableCities() {
    return axiosInstance({
      handlerEnabled: false,
      url: "/public/available-cities",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static deleteInstitution(model) {
    console.log(model, "hey");
    return axiosInstance({
      url: "/admin/delete",
      method: "DELETE",
      data: model,
    });
  }

  static addHospital(model) {
    return axiosInstance({
      url: "/admin/hospital",
      method: "POST",
      data: qs.stringify(model),
    });
  }

  static getHospitalReport(params) {
    return axiosInstance({
      url: "/admin/hospital/report",
      method: "GET",
      params: params,
    });
  }
  static getHospitalList(page, limit) {
    return axiosInstance({
      url: "/admin/hospital-list",
      method: "GET",
      params: {
        page: page,
        limit: limit,
      },
    });
  }
  static login(model) {
    return axiosInstance({
      handlerEnabled: false,
      url: "/registration/admin/login",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(model),
    });
  }

  static getPatients(page, limit) {
    return axiosInstance({
      url: "/admin/patient/list",
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        page: page,
        limit: limit,
      },
    });
  }

  static getProvider(page, limit) {
    return axiosInstance({
      url: "/admin/doctor-list",
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        page: page,
        limit: limit,
      },
    });
  }
  static getUserList(type, text, page, limit) {
    return axiosInstance({
      url: "/admin/userlist",
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        type: type,
        text: text,
        page: page,
        limit: limit,
      },
    });
  }

  static getPatientHealthRecord(userId) {
    return axiosInstance({
      url: `/user/patient/data/:`,
      method: "get",
      params: { userId: userId },
    });
  }

  static deletePatientDoctor(model) {
    return axiosInstance({
      url: "/admin/delete",
      method: "DELETE",
      data: model,
    });
  }

  static getDoctors(doctorStatus, limit = 7, searchKey = "", pageNo = 0) {
    return axiosInstance({
      url: "/admin/manage-providers",
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        doctorStatus: doctorStatus,
        pageNo: pageNo,
        limit: limit,
        searchKey: searchKey,
      },
    });
  }
  static acceptDoctor(
    doctorId,
    followUpDays,
    taxPercentage,
    platformAmount,
    platformAmountType
  ) {
    return axiosInstance({
      url: "/admin/change-status",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        doctorId: doctorId,
        doctorStatus: "active",
        followUpDays: followUpDays,
        taxPercentage: taxPercentage,
        platformAmountType: platformAmountType,
        platformAmount: platformAmount,
      },
    });
  }
  static rejectDoctor(doctorId, reasonForRejection) {
    return axiosInstance({
      url: "/admin/change-status",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        doctorId: doctorId,
        doctorStatus: "rejected",
        rejectReason: reasonForRejection,
      },
    });
  }
  static fetchDoctorDetails(doctorId) {
    return axiosInstance({
      url: `/admin/provider/${doctorId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  static getRefunds(status, pageNo, limit = 5) {
    return axiosInstance({
      url: "/admin/list-cancelled-appointments",
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        pageNo: pageNo,
        limit: limit,
        status: status,
      },
    });
  }

  static acceptOrRejectRefund(scheduleId, status) {
    return axiosInstance({
      url: "/admin/change-refund-status",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        scheduleId: scheduleId,
        status: status,
      },
    });
  }

  static getComplaints(status, pageNo, limit = 5) {
    return axiosInstance({
      url: "/admin/list-issues",
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        pageNo: pageNo,
        limit: limit,
        status: status,
      },
    });
  }

  static acceptOrRejectComplaint(scheduleId, status) {
    return axiosInstance({
      url: "/admin/change-refund-status",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        scheduleId: scheduleId,
        status: status,
      },
    });
  }

  static getMedicineList(params) {
    return axiosInstance({
      url: "/user/medicine-library",
      method: "get",
      params: params,
    });
  }

  static saveNewMedicine(data) {
    return axiosInstance({
      url: "/admin/medicine",
      method: "post",
      data: data,
    });
  }

  static uploadMedicinesFile(payload) {
    return axiosInstance({
      url: "/admin/upload-medicine-file",
      method: "post",
      data: payload,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }

  static getPatientList(searchKey = "", pageNo, limit = 6) {
    return axiosInstance({
      url: "/admin/list-patients",
      method: "get",
      params: {
        pageNo: pageNo,
        limit: limit,
        searchKey: searchKey,
      },
    });
  }

  static editMedicine(data) {
    return axiosInstance({
      url: "/admin/medicine",
      method: "patch",
      data: data,
    });
  }
  static deleteMedicine(id) {
    return axiosInstance({
      url: `/admin/medicine/${id}`,
      method: "delete",
    });
  }
  static fetchServices(params) {
    return axiosInstance({
      url: `/admin/services`,
      method: "get",
      params: params,
    });
  }
  static addService(payload) {
    return axiosInstance({
      url: `/admin/service`,
      method: "post",
      data: payload,
    });
  }
  static editService(payload) {
    return axiosInstance({
      url: `/admin/service`,
      method: "patch",
      data: payload,
    });
  }
  static deleteService(id) {
    return axiosInstance({
      url: `/admin/service/${id}`,
      method: "delete",
    });
  }
  static fetchSpecialization(params) {
    return axiosInstance({
      url: `/admin/specialization`,
      method: "get",
      params: params,
    });
  }
  static addSpecialization(payload) {
    return axiosInstance({
      url: `/admin/specialization`,
      method: "post",
      data: payload,
    });
  }
  static editSpecialization(payload) {
    return axiosInstance({
      url: `/admin/specialization`,
      method: "patch",
      data: payload,
    });
  }
  static uploadSpecializationImage(payload) {
    return axiosInstance({
      url: `/admin/upload/file`,
      method: "put",
      data: payload,
    });
  }
  static editTax(payload) {
    return axiosInstance({
      url: "/admin/change-tax",
      method: "patch",
      data: payload,
    });
  }
  static listAppointments(appointmentStatus, pageNo) {
    return axiosInstance({
      url: `/admin/all-appointments/${appointmentStatus}?page=${pageNo}&limit=10`,
      method: "GET"
    });
  }
  static doctorDetails(id) {
    return axiosInstance({
      url: `/admin/all-providers/${id}`,
      method: "GET"
    });
  }
  static patientDetails(id) {
    return axiosInstance({
      url: `/admin/all-patients/${id}`,
      method: "GET"
    });
  }
  static onboardingCounts(userType, startDate, endDate, queryType) {
    return axiosInstance({
      url: `/admin/stats/onboarding/${userType}/${startDate}/${endDate}/${queryType}`,
      method: "GET"
    });
  }

  // Plans
  static getAllSubscriptionPlans() {
    return axiosInstance({
      url: "/admin/subscriptions/allplans",
      method: "GET"
    });
  }
  static getAllSubscriptionPlansBasedOnCurrency(currency) {
    return axiosInstance({
      url: `/admin/subscriptions/allplans/${currency}`,
      method: "GET"
    });
  }
  static createSubscription(data) {
    return axiosInstance({
      url: "/admin/subscriptions/createplan",
      method: "POST",
      data
    });
  }
  static getSingleSubscriptionDetails(subscriptionId) {
    return axiosInstance({
      url: `/admin/subscriptions/${subscriptionId}`,
      method: "GET"
    });
  }
  static updateSubscription(subscriptionId, data) {
    return axiosInstance({
      url: `/admin/subscriptions/${subscriptionId}`,
      method: "PATCH",
      data
    });
  }
  static deleteSubscription(subscriptionId) {
    return axiosInstance({
      url: `/admin/subscriptions/${subscriptionId}`,
      method: "DELETE"
    });
  }
  static getCountries() {
    return axiosInstance({
      url: "/public/available-country",
      method: "GET",
    });
  }
  static fetchCurrencies(currencySearchText) {
    return axiosInstance({
      url: `/user/currency-codes?searchKey=${currencySearchText}`,
      method: "GET"
    });
  }
  static getCompanySelectedProviders(companyId) {
    return axiosInstance({
      url: `/admin/${companyId}/providers/companyselectedproviders`,
      method: "GET"
    });
  }
  static getCompanyEmployees(companyId, employeeStatus) {
    return axiosInstance({
      url: `/admin/${companyId}/employeeList/${employeeStatus}`,
      method: "GET"
    });
  }

  // Coupons
  static getAllCoupons() {
    return axiosInstance({
      url: "/admin/coupons/allcoupons",
      method: "GET"
    });
  }
  static getSingleCouponDetails(couponId) {
    return axiosInstance({
      url: `/admin/coupons/${couponId}`,
      method: "GET"
    });
  }
  static createCoupon(data) {
    return axiosInstance({
      url: "/admin/coupons/createcoupon",
      method: "POST",
      data
    });
  }
  static updateCoupon(couponId, data) {
    return axiosInstance({
      url: `/admin/coupons/${couponId}`,
      method: "PATCH",
      data
    });
  }
  static deleteCoupon(couponId) {
    return axiosInstance({
      url: `/admin/coupons/${couponId}`,
      method: "DELETE"
    });
  }

  // Categories
  static getAllCategories() {
    return axiosInstance({
      url: "/admin/categories/allcategories",
      method: "GET"
    });
  }
  static getSingleCategoryDetails(categoryId) {
    return axiosInstance({
      url: `/admin/categories/${categoryId}`,
      method: "GET"
    });
  }
  static createCategory(data) {
    return axiosInstance({
      url: "/admin/categories/createcategory",
      method: "POST",
      data
    });
  }
  static updateCategory(categoryId, data) {
    return axiosInstance({
      url: `/admin/categories/${categoryId}`,
      method: "PATCH",
      data
    });
  }
  static deleteCategory(categoryId) {
    return axiosInstance({
      url: `/admin/categories/${categoryId}`,
      method: "DELETE"
    });
  }
  static getAllCompaniesInCategory(categoryId) {
    return axiosInstance({
      url: `/admin/category/${categoryId}/companylists`,
      method: "GET"
    });
  }

  // Companies
  static getAllCompanies(status, pageNo) {
    return axiosInstance({
      url: `/admin/companies/allcompany/${status}?page=${pageNo}&limit=10`,
      method: "GET"
    });
  }
  static getSingleCompanyDetails(companyId) {
    return axiosInstance({
      url: `/admin/companies/${companyId}`,
      method: "GET"
    });
  }
  static updateCompanyStatus(companyId, data) {
    return axiosInstance({
      url: `/admin/companies/${companyId}/updatestatus`,
      method: "PATCH",
      data
    });
  }

  static createForm(data) {
    return axiosInstance({
      url: `/admin/forms/createform`,
      method: "POST",
      data
    });
  }

  static getAllForm(data) {
    return axiosInstance({
      url: `/admin/forms/allforms`,
      method: "GET",
      data
    });
  }

  static getFormByCategory(categoryNumber) {
    return axiosInstance({
      url: `/admin/forms/formcategory/${categoryNumber}`,
      method: "GET",
    });
  }

  static getSingleForm(formId) {
    return axiosInstance({
      url: `/admin/forms/${formId}`,
      method: "GET",
    });
  }

  static updateSingleForm(formId, data) {
    return axiosInstance({
      url: `/admin/forms/${formId}`,
      method: "PUT",
      data
    })
  }

  static deleteSingleForm(formId) {
    return axiosInstance({
      url: `/admin/forms/${formId}`,
      method: "DELETE",
    })
  }

  static fetchFormList(id) {
    return axiosInstance({
      url: `/admin/forms/submitted/patient/${id}`,
      method: "GET"
    });
  }

  static getAllSubmissionSingleForm(id) {
    return axiosInstance({
      url: `/admin/forms/submitted/${id}`,
      method: "GET"
    })
  }

  static getAnswerPatientList(id) {
    return axiosInstance({
      url: `/admin/forms/submitted/${id}/patientlist `,
      method: "GET"
    })
  }

  static getSingleFormSubmittedBySinglePatient(formId, patientId) {
    return axiosInstance({
      url: `/admin/forms/submitted/patient/${patientId}/${formId}`,
      method: "GET"
    })
  }

  static fetchBannerData() {
    return axiosInstance({
      url: `/admin/get-banner-content`,
      method: "GET"
    })
  }

  static addBannerData(data) {
    return axiosInstance({
      url: `/admin/addBannerDetails`,
      method: "POST",
      data
    })
  }

  static updateBannerData(data) {
    return axiosInstance({
      url: `/admin/updateBannerDetails`,
      method: "PATCH",
      data
    })
  }

  static deleteBannerData(data) {
    return axiosInstance({
      url: `/admin/deleteBannerDetail`,
      method: "DELETE",
      data
    })
  }

  static getSpecialisation() {
    return axiosInstance({
      handlerEnabled: true,
      url: "/user/provider/specialization",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  static getOverallStats() {
    return axiosInstance({
      url: `/admin/stats/overall-metrics`,
      method: "GET"
    });
  }

  static updateVisiblity(visiblity, id) {
    return axiosInstance({
      url: `/admin/update-therapist-visible?visible=${visiblity}&id=${id}`,
      method: "GET"
    });
  }

  static waitingDoctorStatus(doctorId) {
    return axiosInstance({
      url: "/admin/change-status",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        doctorId: doctorId,
        doctorStatus: "waiting for approval"
      },
    });
  }
}