import React from "react";
import styled from "styled-components";
import { Card, Col, Row, Button, Typography, Pagination, Spin, message } from "antd";
import ProfileAvatar from "../../../Components/ProfileAvatar";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";

import ApiCalls from "../../../apis/ApiCalls";

import { useState, useEffect } from "react";
import DoctorCard from "../DoctorCard";
import EmptyComponent from "../../../Components/EmptyComponent";

const CardsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

let RejectedDoctorsList = ({ searchTerm = "" }) => {
  let [doctors, setDoctors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [totalPages, setTotalPages] = useState(0);
  let [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getRejectedDoctors();
  }, [currentPage, searchTerm]);

  useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);

  const getRejectedDoctors = async () => {
    try {
      setLoading(true);
      let { data } = await ApiCalls.getDoctors(
        "rejected",
        5,
        searchTerm,
        currentPage
      );
      console.log(data.data);
      setDoctors(data.data.doctors);
      setTotalPages(data.data.doctorCount);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (pageNumber, pageLimit) => {
    setCurrentPage(pageNumber - 1);
  };

  const waitingHandler = async (id) => {
    ApiCalls.waitingDoctorStatus(id)
      .then((response) => {
        if (response.data.status) {
          message.success("Therapist moved to waiting for approval");
          getRejectedDoctors()
        }
      })
      .catch((error) => {

        message.error(
          error.response
            ? error.response.data.data.message
            : "No Response From Server",
          5
        );
      });
  };
  return (
    <>
      {!loading && (
        <CardsWrapper>
          {doctors.length === 0 ? (
            <EmptyComponent message="No data found" />
          ) : null}
          {doctors.map((item, index) => {
            return (
              <DoctorCard
                name={item.name}
                key={item.name}
                email={item.email}
                profilePic={item.profilePic}
                id={item._id}
                cardNumber={index}
                currentPage={currentPage}
                phoneNumber={item.phoneNumber}
                status="reject"
                waitingHandler={waitingHandler}
              />
            );
          })}
        </CardsWrapper>
      )}
      {loading && (
        <Spin
          spinning={loading}
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        />
      )}
      {!loading && (
        <PaginationWrapper>
          <Pagination
            defaultCurrent={1}
            total={totalPages}
            pageSize={5}
            hideOnSinglePage={true}
            onChange={handlePageChange}
            current={currentPage + 1}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

export default RejectedDoctorsList;
