import React from "react";
import styled from "styled-components";
import { Card, Col, Row, Button, Typography, Pagination, Spin } from "antd";
import ProfileAvatar from "../../Components/ProfileAvatar";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import ApiCalls from "../../apis/ApiCalls";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import PatientFile from "./PatientFileModal";
import DeleteModal from "./DeleteModal";
import PatientDetailModal from "./PatientDetailModal";

const Main = styled.div`
  font-family: "Montserrat", sans-serif;
  .ant-pagination {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .ant-spin-container {
    display: flex;
    justify-content: center;
  }
`;
const StyledCard = styled(Card)`
  width: 100%;
  margin: 1% 0;
`;
const CardTitle = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  color: #2959a8;
  text-transform: capitalize;
`;
const CardSubTitle = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #aeaeae;
`;

const PrimaryButton = styled.div`
  .ant-btn-primary {
    border-radius: 25px;
    height: 40px;
    font-size: 12px;
    width: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* svg{
      margin-right:10px;
      align-self: center;
  } */
  }
`;

const DetailButton = styled(Button)`
  /* background-color: #e79c23; */
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;

  border: 0;
`;
const RemoveButton = styled(Button)`
  background-color: #dcdcdc;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #a9a9a9;
  border: 0;
`;

const PatientList = (props) => {
  const [showPatientFile, setShowPatientFile] = useState(false);
  const [patients, setpatients] = useState([]);
  const [selectDeletePatient, setSelectDeletePatient] = useState([]);

  const [selectedPatient, setSelectedPatient] = useState({});
  const [deletePatientModalOpen, setDeletePatientModalOpen] = useState(false);
  const [add, setAdd] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(6);
  const [loading, setLoading] = useState(false);
  const pageLimit = 8;

  const handlePatientFileOpen = (value) => {
    setSelectedPatient(value);
    setShowPatientFile(true);
  };

  const handleDeleteModalOpen = (value) => {
    setSelectDeletePatient(value);
    setSelectedPatient(value);
    setDeletePatientModalOpen(true);
  };

  const handleCancelModal = () => {
    setShowPatientFile(false);
    setSelectedPatient({});
  };

  const handleCancelDeleteModal = () => {
    setDeletePatientModalOpen(false);
  };

  const handlePageChange = (pageNumber, size) => {
    setPageNumber(pageNumber - 1);
  };

  useEffect(() => {
    setLoading(true);

    if (!props.searchKey) {
      ApiCalls.getPatients(pageNumber, pageLimit)
        .then((response) => {
          if (response.data.status === 1) {
            setTotalPages(response.data.data.count);
            setpatients(response.data.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      ApiCalls.getUserList("patient", props.searchKey, pageNumber, pageLimit)
        .then((response) => {
          if (response.data.status === 1) {
            setTotalPages(response.data.data[0].count);
            setpatients(response.data.data[0].data);
            setLoading(false);
            setPageNumber(0);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [pageNumber, add, props.searchKey]);

  return (
    <>
      <Main>
        <Spin
          spinning={loading}
          size="large"
          style={{ display: "flex", justifyContent: "center" }}
        />
        {patients?.map((patientList, index) => {
          return (
            <StyledCard>
              <Row
                gutter={16}
                align="middle"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CardSubTitle style={{ marginRight: "10px" }}>
                    {index + 1 + pageNumber * pageLimit}
                  </CardSubTitle>
                  <Col>
                    <ProfileAvatar
                      icon={<UserOutlined />}
                      size={60}
                      url={patientList.profilePic}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <CardTitle>
                        {patientList.firstName + " " + patientList.lastName}
                      </CardTitle>
                    </Row>
                    <Row>
                      <CardSubTitle>{patientList.mobile}</CardSubTitle>
                    </Row>
                  </Col>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <Col>
                      <PrimaryButton>
                        <RemoveButton
                          type="primary"
                          style={{ fontSize: "16px" }}
                          icon={<DeleteOutlined />}
                          onClick={() => handleDeleteModalOpen(patientList)}
                        >
                          Remove
                        </RemoveButton>
                      </PrimaryButton>
                    </Col>
                  </div>
                  <div>
                    <Col>
                      <PrimaryButton>
                        <DetailButton
                          type="primary"
                          style={{ fontSize: "16px" }}
                          onClick={() => handlePatientFileOpen(patientList)}
                        >
                          View Details
                        </DetailButton>
                      </PrimaryButton>
                    </Col>
                  </div>
                </div>
              </Row>
            </StyledCard>
          );
        })}

        <Modal
          visible={showPatientFile}
          onCancel={handleCancelModal}
          footer={false}
          width={"72%"}
          centered={true}
          destroyOnClose={true}
        >
          {/* <PatientFile

            add={add}
            setAdd={setAdd}
          /> */}
          <PatientDetailModal
            selectedPatient={selectedPatient}
            handleCancelModal={handleCancelModal}
            handleDeleteModalOpen={handleDeleteModalOpen}
          />
        </Modal>

        <Modal
          title={
            <div
              style={{
                display: "flex",
                width: "100%",
                fontSize: "18px",
                fontFamily: "Montserrat",
              }}
            >
              <div>
                <DeleteOutlined />
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <h3>Delete Patient</h3>
              </div>
            </div>
          }
          visible={deletePatientModalOpen}
          onCancel={handleCancelDeleteModal}
          footer={false}
          width={"700px"}
          centered={true}
          destroyOnClose={true}
        >
          <DeleteModal
            selectedUser={selectDeletePatient}
            handleCancelDeleteModal={(event) => {
              handleCancelDeleteModal(false);
            }}
            setAdd={setAdd}
            add={add}
          />
        </Modal>

        <Pagination
          defaultCurrent={1}
          total={totalPages}
          pageSize={pageLimit}
          hideOnSinglePage={true}
          onChange={handlePageChange}
        />
      </Main>
    </>
  );
};

export default PatientList;
