import React from "react";
import styled from "styled-components";
import {
  Card,
  Col,
  Row,
  Button,
  Typography,
  Pagination,
  Spin,
  message,
} from "antd";
import ProfileAvatar from "../../../Components/ProfileAvatar";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";
import ActionModal from "../ActionModal";

import ApiCalls from "../../../apis/ApiCalls";
import Modal from "antd/lib/modal/Modal";
import { useState, useEffect } from "react";
import DoctorCard from "../DoctorCard";
import EmptyComponent from "../../../Components/EmptyComponent";

const CardsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  min-height: 500px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

let WaitingForApprovalList = ({ searchTerm = "" }) => {
  let [doctors, setDoctors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [currentPage, setCurrentPage] = useState(0);
  let [totalPages, setTotalPages] = useState(0);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [currentSelectedDoctor, setCurrentSelectedDoctor] = useState(null);

  useEffect(() => {
    getDoctorsWaitingForApproval();
  }, [searchTerm, currentPage]);

  useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);

  const getDoctorsWaitingForApproval = async () => {
    try {
      setLoading(true);
      let { data } = await ApiCalls.getDoctors(
        "waiting for approval",
        5,
        searchTerm,
        currentPage
      );
      setDoctors(data.data.doctors);
      setTotalPages(data.data.doctorCount);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber, pageLimit) => {
    setCurrentPage(pageNumber - 1);
  };

  const acceptHandler = (id, name, email) => {
    setCurrentSelectedDoctor({
      id: id,
      name: name,
      email: email,
      action: "accept",
    });
    setModalVisibility(true);
  };

  const rejectHandler = (id, name, email) => {
    setCurrentSelectedDoctor({
      id: id,
      name: name,
      email: email,
      action: "reject",
    });
    setModalVisibility(true);
  };

  const handleModelConfirm = async (confirmationType, reason) => {
    try {
      let response;
      if (confirmationType === "accept") {
        response = await ApiCalls.acceptDoctor(
          reason.doctorId,
          reason.followUpDays,
          reason.taxPercentage,
          reason.platformAmount,
          reason.platformAmountType
        );
      }
      if (confirmationType === "reject") {
        response = await ApiCalls.rejectDoctor(
          reason.doctorId,
          reason.rejectReason
        );
      }
      if (response.data.status) {
        message.success(`Successfully ${confirmationType}ed the therapist`);
      }
      setLoading(true);
      setModalVisibility(false);
      let { data } = await ApiCalls.getDoctors(
        "waiting for approval",
        5,
        searchTerm,
        currentPage
      );
      setDoctors(data.data.doctors);
      setTotalPages(data.data.doctorCount);
      setLoading(false);
    } catch (error) {
      message.error(
        error.response
          ? error?.response?.data?.data?.message
          : "No Response From Server",
        5
      );
    }
  };

  return (
    <>
      <CardsWrapper>
        {!loading && (
          <>
            {doctors.length === 0 ? (
              <EmptyComponent message="No data found" />
            ) : null}
            {doctors.map((item,index) => {
              return (
                <DoctorCard
                  accept
                  reject
                  name={item.name}
                  key={item.name}
                  email={item.email}
                  acceptHandler={acceptHandler}
                  rejectHandler={rejectHandler}
                  id={item._id}
                  profilePic={item.profilePic}
                  cardNumber={index}
                  currentPage={currentPage}
                  phoneNumber={item.phoneNumber}
                />
              );
            })}
          </>
        )}
      </CardsWrapper>
      {loading && <Spin spinning={true} size="large" />}
      <Modal
        visible={isModalVisible}
        onCancel={() => {
          setModalVisibility(false);
        }}
        footer={false}
        width={"72%"}
        centered={true}
        destroyOnClose={true}
      >
        <ActionModal
          selectedDoctor={currentSelectedDoctor}
          handleModelConfirm={handleModelConfirm}
        />
      </Modal>

      {!loading && (
        <PaginationWrapper>
          <Pagination
            defaultCurrent={1}
            total={totalPages}
            pageSize={5}
            hideOnSinglePage={true}
            onChange={handlePageChange}
            current={currentPage + 1}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

export default WaitingForApprovalList;
