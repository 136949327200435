import React, { useState, useEffect } from "react";
import { Layout, Menu, Col } from "antd";
import styled from "styled-components";
import {
  Link,
  Route,
  Switch as RouterSwitch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import AdminLinks from "./adminLinks";
import { compose } from "redux";
import ManageUsers from "../ManageUsers";
import ManageReports from "../manage-reports";
import ManageInstitutions from "../manage-institutions";
import InsuranceClaims from "../insurance-claims";
import EmptyComponent from "../../Components/EmptyComponent";
import PatientInsurance from "../patient-insurance-cards";
import ManageDoctors from "../ManageDoctors"
import ManageRefunds from "../manage-refunds";
import ManageComplaints from "../manage-complaints";
import MedicineLibrary from "../MedicineLibrary";
import ListPatients from "../list-patients";
import ManageServices from "../ManageServices";
import ManageSpecializations from "../ManageSpecializations";
import ListAppointments from "../list-appointments";
import Dashboard from "../dashboard";
import ManageCorporates from '../manage-corporates';
import ManageCoupons from '../manage-coupons';
import ManageCategories from '../manage-categories';
import ManageCompanies from '../manage-companies';
import FormList from "../survay-form/FormList";
import CreateForm from "../survay-form/CreateForm";
import FormDetails from "../survay-form/FormDetails";
import UpdateForm from "../survay-form/UpdateForm";
import ViewAnswers from "../survay-form/ViewAnswers";
import ViewProfile from "../survay-form/ViewProfile";
import IndividualAnswers from "../survay-form/IndividualAnswer";
import ManageBanners from "../manage-banners";
import Subscriptions from "../subscription";

const { Sider, Content } = Layout;

const StyledMenu = styled(Menu)`
  li {
    display: flex;
    align-items: center;
    padding-left: 0px;
    & > a {
      display: flex;
      margin-right: 32px;
      color: #000000;
    }
  }
  .ant-menu-item {
    a {
      color: #6a6a6a;
      font-family: "Montserrat", sans-serif;
    }
  }
  .ant-menu-item-selected {
    background-color: #fffcf0 !important;
    a {
      color: #2959a8 !important;
    }
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  font-weight: 600;
`;

const Home = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeKey, setActiveKey] = useState("0");
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const onSelect = (param) => {
    setActiveKey(param.key);
  };
  
  useEffect(() => {
    setActiveKey(() => {
      const activeLink = AdminLinks.find(
        (link) => props.location.pathname === link.path
      );

      return activeLink ? activeLink.key : "0";
    });
  }, [props.location.pathname]);

  return (
    <Layout style={{ marginTop: "64px", background:"#fff"}} color="#fff">
      <Sider
        width="266px"
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{ position: "fixed", height: "calc(100% - 64px)", overflowY: 'auto' }}
        breakpoint="md"
        theme="light"
      >
        <StyledMenu
          theme="light"
          mode="inline"
          defaultSelectedKeys={activeKey}
          onSelect={onSelect}
          selectedKeys={[activeKey]}
        >
          {AdminLinks.map((mainLink) => (
            <StyledMenuItem
              key={mainLink.key}
              icon={
                <Link to={mainLink.path}>
                  <mainLink.icon
                    width="24px"
                    height="24px"
                    active={mainLink.key === activeKey}
                  />
                </Link>
              }
            >
              <Link to={mainLink.path}>{mainLink.name}</Link>
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </Sider>
      <Content style={{ margin: "auto", height: "calc(100vh - 64px)" }}>
        <Col
          xs={{ offset: 5 }}
          sm={{ offset: 3 }}
          md={{ offset: 8 }}
          lg={{ offset: 6 }}
          xl={{ offset: 5 }}
          xxl={{ offset: 3 }}
        >
          <RouterSwitch>
            <Route
              path="/base/home/dashboard"
              render={(props) => <Dashboard {...props} />}
            />
            <Route
              path="/base/home/manage-users"
              render={(props) => <ManageUsers {...props} />}
            />
            <Route
              path="/base/home/manage-reports"
              render={(props) => <ManageReports {...props} />}
            />
            <Route
              path="/base/home/manage-institutions"
              render={(props) => <ManageInstitutions {...props} />}
            />
            <Route
              path="/base/home/insurance-claims"
              render={(props) => <InsuranceClaims {...props} />}
            />
            <Route
              path="/base/home/insurance-card"
              render={(props) => <PatientInsurance {...props} />}
            />
            <Route
              path="/base/home/manage-doctors"
              render={(props) => <ManageDoctors {...props} />}
            />
            <Route
              path="/base/home/manage-refunds"
              render={(props) => <ManageRefunds {...props} />}
            />
            <Route
              path="/base/home/manage-complaints"
              render={(props) => <ManageComplaints {...props} />}
            />
            <Route
              path="/base/home/list-appointments"
              render={() => <ListAppointments />}
            />
            <Route
              path="/base/home/list-patients"
              render={() => <ListPatients />}
            />
            <Route
              path="/base/home/medicine-library"
              render={() => <MedicineLibrary />}
            />
            <Route
              path="/base/home/services"
              render={() => <ManageServices />}
            />
            <Route
              path="/base/home/specializations"
              render={() => <ManageSpecializations />}
            />
            <Route
              path="/base/home/corporate-subscriptions"
              render={() => <ManageCorporates />}
            />
            <Route
              path="/base/home/manage-coupons"
              render={() => <ManageCoupons />}
            />
            <Route
              path="/base/home/manage-categories"
              render={() => <ManageCategories />}
            />
            <Route
              path="/base/home/manage-companies"
              render={() => <ManageCompanies />}
            />
             
            <Route
              path="/base/home/survay-form/create-form"
              render={() => <CreateForm />}
            />
            <Route
              path="/base/home/survay-form/update-form/:id"
              render={() => <UpdateForm />}
            />
            <Route
              path="/base/home/survay-form/form-details/individual-answers/:formId/:patientId"
              render={() => <IndividualAnswers />}
            />
            <Route
              path="/base/home/survay-form/form-details/view-answers/View-in-details/:id"
              render={() => <ViewProfile />}
            />
            
            <Route
              path="/base/home/survay-form/form-details/view-answers/:id"
              render={() => <ViewAnswers />}
            />
            <Route
              path="/base/home/survay-form/form-details/:id"
              render={() => <FormDetails />}
            />
            <Route
              path="/base/home/survay-form"
              render={() => <FormList />}
            />
            <Route
              path="/base/home/banner-content"
              render={() => <ManageBanners />}
            />
            <Route
              path="/base/home/subscription"
              render={() => <Subscriptions />}
            />
            <Route
              path="*"
              exact={true}
              render={() => (
                <EmptyComponent
                  message="404,Page not found"
                  minHeight="100vh"
                />
              )}
            />
          </RouterSwitch>
        </Col>
      </Content>
    </Layout>
  );
};
const mapStateToProps = (store) => {
  return {
    userData: store.auth.userData,
  };
};
export default compose(connect(mapStateToProps), withRouter)(Home);
